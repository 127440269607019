import React, {useState} from 'react';
import classes from './Source.module.css';
import cl from '../Statistics.module.css';
import {getCurrencySymbol} from "../../../../core/helpers";

const Source = ({source, data}) => {
    const [open, setOpen] = useState(false);
    const postbackCount = data.length ? data.reduce((sum, elem)=>sum + elem.postbackCount,0) : 0;
    const webIncome = data.length ? data.reduce((sum, elem)=>sum + elem.webIncome, 0) : 0;
    const webCosts = data.length ? data.reduce((sum, elem)=>sum + elem.webCosts, 0) : 0;
    const webProfit = data.length ? data.reduce((sum, elem)=>sum + elem.webProfit, 0) : 0;

    return (
        <>
            <div className={open ? `${cl.tr} ${classes.Open} ${classes.Parent}` : `${cl.tr} ${classes.Parent}`} onClick={()=>setOpen(!open)}>
                <div className={cl.td}>
                    {source.source}

                </div>
                <div className={cl.td}>{postbackCount}</div>
                <div className={cl.td}>{webIncome.toFixed(2)}</div>
                <div className={cl.td}>{webCosts.toFixed(2)}</div>
                <div className={cl.td}>{webProfit.toFixed(2)}</div>
            </div>
            {open && (
                <>
                    {source.data.map(function (web) {
                        const index = data.findIndex(item=>item.utmContent===web.web);
                        const postbackCount = index < 0 ? 0 : data[index].postbackCount;
                        const webIncome = index < 0 ? 0 : data[index].webIncome;
                        const webCosts = index < 0 ? 0 : data[index].webCosts;
                        const webProfit = index < 0 ? 0 : data[index].webProfit;
                        const currency = index < 0 ? '' : data[index].currency;

                        return (
                            <div className={`${cl.tr} ${classes.Child}`} key={web.web}>
                                <div className={cl.td}>{web.web} {getCurrencySymbol(currency)}</div>
                                <div className={cl.td}>{postbackCount}</div>
                                <div className={cl.td}>{webIncome.toFixed(2)}</div>
                                <div className={cl.td}>{webCosts.toFixed(2)}</div>
                                <div className={cl.td}>{webProfit.toFixed(2)}</div>
                            </div>
                        )
                    })}
                </>
            )}
        </>
    );
};

export default Source;
