import React, {useContext, useEffect, useState} from 'react';
import Input from "../../../../ui/input/Input";
import Button from "../../../../ui/button/Button";
import {login} from '../../../../../actions/userActions';
import {AppContext} from "../../../../../context";

const Auth = () => {
    const {setIsAuth} = useContext(AppContext);
    const {setIsLoading} = useContext(AppContext);
    const {notifications, setNotifications} = useContext(AppContext);
    const userErrors = false;
    const [fields, setFields] = useState({
        email: { value: '', error: false },
        password: { value: '', error: false },
    });

    useEffect(()=>{
        if(userErrors){
            let theFields = {...fields};
            userErrors.map((error)=>{
                if(error.param in fields){
                    theFields[error.param] = {
                        value: fields[error.param].value,
                        error: error.msg
                    };
                }
                return error;
            });
            setFields({...theFields});
        }
        // eslint-disable-next-line
    },[userErrors]);

    return (
        <div id={'authorization'}>
            <form autoComplete="off">
                <div className="mb-3">
                    <Input
                        type='email'
                        name='email'
                        placeholder='Электронная почта'
                        value={fields.email.value}
                        setValue={(val)=>{
                            setFields({...fields, email: {value: val, error: false}});
                        }}
                        error={fields.email.error}
                    />
                </div>
                <div className="mb-3">
                    <Input
                        type='password'
                        name='password'
                        placeholder='Пароль'
                        value={fields.password.value}
                        setValue={(val)=>{
                            setFields({...fields, password: {value: val, error: false}});
                        }}
                        error={fields.password.error}
                    />
                </div>
                <Button type='primary' onClick={(e)=>{e.preventDefault(); login(fields.email.value, fields.password.value, setIsAuth, setIsLoading, notifications, setNotifications)}}>
                    Войти
                </Button>
                <Button type='link' onClick={ () => { return false; } }>
                    Забыли пароль?
                </Button>
            </form>
        </div>
    );
};

export default Auth;
