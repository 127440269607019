import React from 'react';
import classes from './Button.module.css';

const Button = ({children, ...props}) => {
    let btnClasses = props.className ? `${props.className} ` : '';
    switch (props.type) {
        case 'login':
            btnClasses += `${classes.app_btn} ${classes.loginTab}`;
            break;
        case 'login active':
            btnClasses += `${classes.app_btn} ${classes.loginTab} ${classes.loginTabActive}`;
            break;
        case 'link':
            btnClasses += `${classes.app_btn} ${classes.link}`;
            break;
        case 'primary':
            btnClasses += `${classes.app_btn} ${classes.primary}`;
            break;
        case 'pay':
            btnClasses += `${classes.app_btn} ${classes.pay}`;
            break;
        case 'casual':
            btnClasses += `${classes.app_btn} ${classes.casual}`;
            break;
        case 'vip':
            btnClasses += `${classes.app_btn} ${classes.vip}`;
            break;
        default:
            btnClasses += `${classes.app_btn}`;
            break;
    }

    return (
        <button {...props} type='button' className={btnClasses}>
            {children}
        </button>
    );
};

export default Button;