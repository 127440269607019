import React from 'react';
import classes from "./AuthBox.module.css";
import Auth from "./authorization/Auth";
import Button from "../../../ui/button/Button";

const AuthBox = () => {
    return (
        <div className={classes.app_auth_box}>
            <div className={classes.app_auth__header}>
                <Button type='login active'>
                    Вход
                </Button>
            </div>
            <Auth />
        </div>
    );
};

export default AuthBox;
