import './App.css';
import React, {useEffect, useState} from "react";
import {AppContext} from "../../context";
import AdminMain from "../pages/adminMain/AdminMain";
import LoginPage from "../pages/loginPage/LoginPage";
import {checkAuth} from "../../actions/userActions";
import AppLoader from "../ui/appLoader/AppLoader";
import Notifications from "../notifications/Notifications";

function App() {
    const [isAuth, setIsAuth] = useState({isAuth: false, capability: 10}); // 10 - to set absolute minimum capability from the beginning
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        if(localStorage.getItem('admToken')){
            checkAuth(setIsAuth, setIsLoading, notifications, setNotifications);
        }else{
            console.log('Not authorized user');
        }
        // eslint-disable-next-line
    }, []);

    return (
        <AppContext.Provider value={{
            isAuth, setIsAuth,
            isLoading, setIsLoading,
            notifications, setNotifications
        }}>
            { isLoading
                ? <AppLoader/>
                :
                <>
                    { isAuth.isAuth
                        ? <AdminMain/>
                        : <LoginPage/>
                    }
                </>
            }

          <Notifications/>
      </AppContext.Provider>
    );
}

export default App;
