import React, {useEffect, useState} from 'react';
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import classes from '../Input.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from  "react-datepicker";
import { ru } from 'date-fns/locale';
import InputError from "../../inputError/InputError";
registerLocale('ru', ru);

const InputDate = (props) => {
    const [focused, setFocused] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = (e) => {if(!e.target.value){ setFocused(false);}};
    const onChange = (date) => {
        if(date){
            setFocused(true)
        }else{
            setFocused(false)
        }

        props.setValue(date)
    };

    useEffect(()=>{
        if(props.value){
            setFocused(true);
        }
    },[props.value]);

    return (
        <div className={classes.app__field__row}>
            {props.label && <div className={classes.app__input__label}>{props.label}</div>}
            <div className={focused ? `${classes.app__input} ${classes.focused}` : `${classes.app__input}`}>
                <DatePicker
                    locale="ru"
                    selected={props.value}
                    onChange={(date) => onChange(date)}
                    dateFormat="dd.MM.yyyy"
                    showYearDropdown
                    onFocus={onFocus}
                    onBlur={(e)=>{onBlur(e)}}
                    customInput={<InputMask mask="99.99.9999" {...(props.error && {className:classes.error})} />}
                />
                <div aria-hidden="true" className={classes.app__input__placeholder}>{props.placeholder}</div>
                {props.error && <InputError error={props.error}/>}
            </div>
        </div>
    );
};

export default InputDate;
