import React, {useContext, useState} from 'react';
import cl from "./SeoPages.module.css";
import {AppContext} from "../../../context";
import {importXlsData} from "../../../actions/seoActions";

const ImportXls = () => {
    const {notifications, setNotifications} = useContext(AppContext);
    const [file, setFile] = useState(null);
    const [importResult, setImportResult] = useState(null);

    const importXls = () => {
        // const formData = new FormData();
        importXlsData( file, setImportResult, notifications, setNotifications);
    }

    return (
        <div className={cl.importWrap}>
            <h2 className="my-3 text-center">Импорт SEO страниц из XLS</h2>

            <div className="row">
                <div className="col-lg-4 offset-lg-1">
                    <label className="d-block mb-3">Выберете xls файл для импорта:</label>
                    <input onChange={ e => setFile( e.target.files[0] ) } type="file" id="upload" name="upload" accept=".xls,.xlsx"/>
                    <button className={cl.importBtn} onClick={importXls}>Импорт</button>
                </div>
            </div>

            {importResult &&
                <>
                    <h4 className="my-5">Ипортировано страниц: {importResult.created}</h4>
                    {importResult.duplicated.length > 0 &&
                        <>
                            <h4 className="my-4">Дублированных страниц: {importResult.duplicated.length}</h4>
                            {importResult.duplicated.map((page, index) =>
                                <div key={index}>
                                    <b>Город:</b> {page.city}, <b>slug:</b> {page.slug}
                                </div>
                            )}
                        </>
                    }
                </>
            }

        </div>
    );
};

export default ImportXls;
