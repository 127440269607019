import React from 'react';
import classes from './Select.module.css';
import InputError from "../inputError/InputError";

const Select = (props) => {
    const {options, defaultValue, value, onChange, error=false, label, ...rest} = props;
    const selectClasses = value ? `${classes.app__select} ${classes.selected}` : `${classes.app__select}`;
    return (
        <div className={classes.app__field__row}>
            {label && <div className={classes.app__select__label}>{label}</div>}
            <div className={selectClasses}>
                <select {...rest}
                    value={value}
                    onChange={event => onChange(event.target.value)}
                    {...(error && {className:classes.error})}

                >
                    {defaultValue && (
                        <option disabled value="0">{defaultValue}</option>
                    )}
                    {options.map(option =>
                        <option key={option.value} value={option.value}>
                            {option.name}
                        </option>
                    )}
                </select>
                {error && <InputError error={error}/>}
            </div>
        </div>
    );
};

export default Select;
