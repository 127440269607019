import React from 'react';
import cl from './Textarea.module.css';
import InputError from "../inputError/InputError";

const Textarea = ({className, error, ...props}) => {
    return (
        <div className={`${cl.textareaApp} ${className} ${(error ? cl.error : '')}`}>
            <textarea {...props}></textarea>
            {error && <InputError error={error}/>}
        </div>
    );
};

export default Textarea;