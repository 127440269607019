import { v4 as uuidv4 } from 'uuid';

export const createNotification =
    ({type, children, className=false, link=false}, notifications, setNotifications) => {
    try {
        const id = uuidv4();
        setNotifications([...notifications,
                {
                    id: id,
                    children: children,
                    type: type,
                    className: className,
                    link: link
                }
            ]);
    } catch (e) {
        console.log('Can not create notification');
    }
};

export const deleteNotificationAction = ( id, notifications, setNotifications) => {
    setNotifications([
        ...notifications.filter(notification => notification.id !== id)
    ]);
}
