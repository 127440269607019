import React from 'react';
import cl from './Spinner.module.css';

const Spinner = ({size}) => {
    const classes = [cl.spinner];
    if(size){
        classes.push(cl[size])
    }

    return (
        <div className={classes.join(' ')}>
        </div>
    );
};

export default Spinner;