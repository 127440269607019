import {createNotification} from "../actions/appActions";

export const getCurrencySymbol = (i) => {
    if(i === 0)
        return "";

    if(i === 4)
      return "₽";

    if(i === 2)
        return "$";

    return "";
};

export const isPremium = (time) => {
    return time > Math.round(Date.now() / 1000);
};

export const errorProcessing = (e, setFieldsErrors, notifications, setNotifications) => {
    if (e.response && e.response.data && e.response.data.errors){
        const errorsArr = e.response.data.errors;
        if (errorsArr.length > 0) {
            let errors = {};
            errorsArr.map(error => {
                errors[error.param] = error.msg;
                return error;
            });
            setFieldsErrors(errors);
        } else {
            const msgText = e.response.data.message;
            if (msgText) {
                createNotification({
                    type: 'error',
                    children: msgText
                }, notifications, setNotifications);
            }
        }
    } else {
        createNotification({
            type: 'error',
            children: e.message
        }, notifications, setNotifications);
    }
}

