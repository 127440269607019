import React, {useEffect, useMemo, useState} from 'react';
import InputDate from "../../ui/input/date/InputDate";
import cl from "./Statistics.module.css";
import Button from "../../ui/button/Button";
import {getStatistics, getWebs} from "../../../actions/statActions";
import ContentLoader from "../../ui/contentLoader/ContentLoader";
import {useDispatch, useSelector} from "react-redux";
import Source from "./source/Source";

const Statistics = () => {
    const [fields, setFields] = useState({
        startDate: { value: '', error: false },
        endDate: { value: '', error: false }
    });
    const [data, setData] = useState([]);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const webs = useSelector(state => state.stat.webs);
    const loaded = useSelector(state => state.stat.loaded);

    useEffect(()=>{
        const fetchWebs = async () => {
            await dispatch(getWebs());
            setLoading(false);
        };
        if(!loaded){
            fetchWebs();
        }else{
            setLoading(false);
        }
    },[dispatch, loaded]);

    useEffect(()=>{
        console.log(data);
    },[data]);


    const getStat = async () => {
        setLoading(true);
        const fetchData = await getStatistics({start: fields.startDate.value, end: fields.endDate.value});
        setData(fetchData.report);
        setLoading(false);
    };

    // const webView = useMemo(()=>{
    //     return webs.map(source=>(
    //         <Source source={source} data={data.filter(item=>item.utmSource === source.source)} key={source.source}/>
    //     ));
    // },[webs, data]);

    const webView = useMemo(()=>{
        return webs.map(source=>(
            <Source source={source} data={data.filter(item=>item.utmSource === source.source)} key={source.source}/>
        ));
    },[webs, data]);

    return (
        <div className={cl.Statistics}>
            <h1>Статистика</h1>
            <div className={cl.Form}>
                <InputDate
                    value={fields.startDate.value}
                    setValue={(val)=>{
                        setFields({...fields, startDate: {value: val, error: false}});
                    }}
                    error={fields.startDate.error}
                    placeholder='Начальная дата'
                />
                <InputDate
                    value={fields.endDate.value}
                    setValue={(val)=>{
                        setFields({...fields, endDate: {value: val, error: false}});
                    }}
                    error={fields.endDate.error}
                    placeholder='Конечная дата'
                />
                <Button type={'primary'} onClick={getStat}>Запросить</Button>
            </div>
            <div className={cl.Data}>
                {loading ? (<ContentLoader/>) : (
                    <div className={cl.DataContent}>
                        {webs.length === 0 ? <span>Вебов нет.</span> : (
                            <div className={cl.Table}>
                                <div className={cl.tr}>
                                    <div className={cl.th}>Источники, вебы</div>
                                    <div className={cl.th}>Кол-во отправленных постбеков</div>
                                    <div className={cl.th}>Сумма успешных списаний</div>
                                    <div className={cl.th}>Расход (постбеков отправлено на сумму)</div>
                                    <div className={cl.th}>Прибыль</div>
                                </div>
                                {webView}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Statistics;
