import React, {useEffect, useState} from 'react';
import $api from "../../../core/api";
import Page from "./page/Page";

const SeoPagesList = () => {
    const [pages, setPages] = useState([]);

    useEffect(() => {
        $api.get(`/api/seo/pages`, {withCredentials: true})
            .then((response) => {
                if ( response.data.success === true ){
                    setPages(response.data.pages);
                }
            })
            .catch((e) => {
                console.log(e.message);
            });
    }, []);

    return (
        <>
            <h2 className="mt-3 text-center">SEO страницы</h2>

            <div className="mainContentWrapper">
                <div className="row no-gutters">
                    <div className="col-lg-4 font-weight-bold border-dark-1 py-2 text-center">Город</div>
                    <div className="col-lg-4 font-weight-bold border-dark-1 py-2 text-center">Slug</div>
                    <div className="col-lg-4 font-weight-bold border-dark-1 py-2 text-center">Действия</div>
                </div>

                {pages.length > 0
                    ?
                    pages.map( page => <Page key={page.slug} page={page} /> )
                    :
                    <div className="text-center py-3">Страницы не найдены</div>
                }
            </div>
        </>
    );
};

export default SeoPagesList;
