import React, {useContext, useState} from 'react';
import Button from "../../ui/button/Button";
import Input from "../../ui/input/Input";
import Select from "../../ui/select/Select";
import $api from "../../../core/api";
import {createNotification} from "../../../actions/appActions";
import {AppContext} from "../../../context";
import {errorProcessing} from "../../../core/helpers";

const Offer = ({offer, removeOffer}) => {
    const {notifications, setNotifications} = useContext(AppContext);
    const [currentOffer, setCurrentOffer] = useState(offer);
    const [edit, setEdit] = useState(false);
    const [fieldsErrors, setFieldsErrors] = useState({cpa:"", type:"", goal:"", payout:"", currency:""});

    const startEdit = () => {
        setEdit(true);
    }

    const cancelEdit = () => {
        setEdit(false);
        setCurrentOffer(offer);
    }

    const deleteOffer = () => {
        if ( window.confirm('Удаление оффера. Вы уверены?') ) {
            $api.post("/api/admin/delete-offer", {offerId: offer._id}, {withCredentials: true})
                .then((response) => {
                    if (response.data.success === true) {
                        removeOffer(offer._id);
                    }
                })
                .catch((e) => {
                    createNotification({
                        type: 'error',
                        children: e.message
                    }, notifications, setNotifications);
                });
        }
    }

    const updateOffer = () => {
        setFieldsErrors({cpa:"", type:"", goal:"", payout:"", currency:""});
        $api.post("/api/admin/update-offer", currentOffer,  {withCredentials: true})
            .then((response) => {
                if ( response.data.success === true ){
                    setCurrentOffer(response.data.offer);
                    setEdit(false);
                }
            })
            .catch((e) => errorProcessing(e, setFieldsErrors, notifications, setNotifications));
    }

    return (
        <div className="row no-gutters">
            <div className="col-lg-2 border-bottom py-2 text-center">
                <div className="input-block">
                    <Input type={"text"}
                           value={currentOffer.cpa}
                           setValue={ (value) => setCurrentOffer( {...currentOffer, cpa:value}) }
                           error={fieldsErrors.cpa}
                           disabled={!edit}
                    />
                </div>
            </div>
            <div className="col-lg-2 border-bottom py-2 text-center">
                <div className="input-block">
                    <Input type={"text"}
                           value={currentOffer.type}
                           setValue={ (value) => setCurrentOffer( {...currentOffer, type:value}) }
                           error={fieldsErrors.type}
                           disabled={!edit}
                    />
                </div>
            </div>
            <div className="col-lg-3  border-bottom py-2 text-center">
                <div className="input-block">
                    <Input type={"text"}
                           value={currentOffer.goal}
                           setValue={ (value) => setCurrentOffer( {...currentOffer, goal:value}) }
                           error={fieldsErrors.goal}
                           disabled={!edit}
                    />
                </div>
            </div>
            <div className="col-lg-2 border-bottom py-2 text-center">
                <div className="input-block">
                    <Input type={"number"}
                           value={currentOffer.payout}
                           setValue={ (value) => setCurrentOffer( {...currentOffer, payout:value}) }
                           error={fieldsErrors.payout}
                           disabled={!edit}
                    />
                </div>
            </div>
            <div className="col-lg-1 border-bottom py-2 text-center">
                <div className="input-block">
                    <Select
                        value={currentOffer.currency}
                        onChange={(value)=>{
                            setCurrentOffer({...currentOffer, currency:value});
                        }}
                        defaultValue="Валюта..."
                        options={[
                            {value: 1, name: 'EUR'},
                            {value: 2, name: 'USD'},
                            {value: 3, name: 'UAH'},
                            {value: 4, name: 'RUB'},
                            {value: 5, name: 'TNG'},
                        ]}
                        error={fieldsErrors.currency}
                        disabled={!edit}
                    />
                </div>
            </div>
            <div className="col-lg-2 font-weight-bold border-bottom text-center">
                <div className="input-block">
                    {edit
                        ?
                        <>
                            <Button type={'link'} onClick={updateOffer} style={{padding: 0}}>Сохранить</Button>
                            <br/>
                            <Button type={'link'} onClick={cancelEdit} style={{padding: 0, color: 'chocolate'}}>Отменить</Button>
                        </>
                        :
                        <>
                            <Button type={'link'} style={{padding: 0}} onClick={startEdit}>Редактировать</Button>
                            <br/>
                            <Button type={'link'} style={{padding: 0, color: 'red'}} onClick={deleteOffer}>Удалить</Button>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default Offer;
