import React from 'react';
import cl from "./CollapsedMenu.module.css";
import {NavLink} from "react-router-dom";

const CollapsedMenu = ({links, collapsed}) => {
    return (
        <ul className={`${cl.secondLevel} ${collapsed && cl.collapsed}`}>
            {links.map(
                link => <li
                    key={link.slug}
                    onClick={(e) => e.stopPropagation() }>
                    <NavLink
                        style={isActive => ({
                            backgroundColor: isActive ? "rgb(44 51 56)" : "transparent"
                        })}
                        to={link.slug}>{link.name}</NavLink>
                </li>
            )}
        </ul>
    );
};

export default CollapsedMenu;
