import React, {useContext, useEffect, useState} from 'react';
import Input from "../../ui/input/Input";
import Button from "../../ui/button/Button";
import {AppContext} from "../../../context";
import Textarea from "../../ui/textarea/Textarea";
import {addSeoPage, getSeoPageData, updateSeoPage} from "../../../actions/seoActions";
import cl from "./SeoPages.module.css";

const CreateSeoPage = ({location}) => {
    const isEdit = location.search.indexOf('pageSlug') !== -1;
    const pageInitialState = {
        cityName: '',
        slug: '',
        seoText: { header: '', p1: '', p2: '', p3: '', list: ''},
        seoTextMale: { header: '', p1: '', p2: '', p3: '', list: ''},
        seoTextFemale: { header: '', p1: '', p2: '', p3: '', list: ''}
    }

    const {notifications, setNotifications} = useContext(AppContext);
    const [pageData, setPageData] = useState(pageInitialState);
    const [edit, setEdit] = useState(false);
    const [fieldsErrors, setFieldsErrors] = useState({cityName:"", slug:""});
    const [activeTab, setActiveTab] = useState('main');

    useEffect(() => {
        if (isEdit) {
            setEdit(true);
            const pageSlug = location.search.substring(10);
            getSeoPageData(pageSlug, setPageData, notifications, setNotifications)
        } else {
            setEdit(false);
            setPageData(pageInitialState);
        }
        // eslint-disable-next-line
    }, [isEdit]);

    useEffect(() => {
        if (pageData.seoText.list){
            const listArray = pageData.seoText.list.split(';;');
            setPageData({...pageData, ul: listArray});
        }
        // eslint-disable-next-line
    }, [pageData.seoText.list]);

    useEffect(() => {
        if (pageData.seoTextMale.list){
            const listArray = pageData.seoTextMale.list.split(';;');
            setPageData({...pageData, ulMale: listArray});
        }
        // eslint-disable-next-line
    }, [pageData.seoTextMale.list]);

    useEffect(() => {
        if (pageData.seoTextFemale.list){
            const listArray = pageData.seoTextFemale.list.split(';;');
            setPageData({...pageData, ulFemale: listArray});
        }
        // eslint-disable-next-line
    }, [pageData.seoTextFemale.list]);

    const createPage = () => {
        addSeoPage(pageData, setPageData, notifications, setNotifications, setFieldsErrors, setEdit);
    };

    const updatePage = () => {
        updateSeoPage(pageData, setPageData, notifications, setNotifications, setFieldsErrors);
    }

    return (
        <>
            <h2 className="my-3 text-center">{edit ?'Редактирование страницы' : 'Новая Seo страница'  }</h2>

            <form>
                <div className="row">
                    <div className="col-lg-4 my-3">
                        <h3 className="mb-1 pl-1">Город:</h3>
                        <Input type={"text"}
                               value={pageData.cityName}
                               setValue={ (value) => setPageData( {...pageData, cityName:value}) }
                            error={fieldsErrors.cityName}
                        />
                    </div>

                    <div className="col-lg-3 my-3">
                        <h3 className="mb-1 pl-1">Url slug:</h3>
                        <Input type={"text"}
                               value={pageData.slug}
                               setValue={ (value) => setPageData( {...pageData, slug:value}) }
                            error={fieldsErrors.slug}
                        />
                    </div>
                </div>

                <h3 className="mb-3">Seo текст:</h3>

                <ul className={cl.navTabs}>
                    <li className={`${cl.navItem} ${activeTab === 'main' && cl.active}`} onClick={() => setActiveTab('main')}>Основная</li>
                    <li className={`${cl.navItem} ${activeTab === 'female' && cl.active}`} onClick={() => setActiveTab('female')}>Девушки</li>
                    <li className={`${cl.navItem} ${activeTab === 'male' && cl.active}`} onClick={() => setActiveTab('male')}>Парни</li>
                </ul>

                <div id="mainTabBlock" className={`row ${cl.tabBlock} ${activeTab === 'main' && cl.active}`}>
                    <div className="col-12 my-3">
                        <h4 className="mb-1 pl-1">Заголовок:</h4>
                        <Input type={"text"}
                               value={pageData.seoText.header}
                               setValue={ (value) => setPageData( {...pageData, seoText: {...pageData.seoText, header: value} }) }
                        />

                        <h4 className="mt-3 mb-1 pl-1">Первый абзац:</h4>
                        <Textarea
                            value={pageData.seoText.p1}
                            onChange={(e)=>{
                                setPageData({...pageData, seoText: {...pageData.seoText, p1: e.target.value} });
                            }}
                        />

                        <h4 className="mb-1 pl-1">Второй абзац:</h4>
                        <Textarea
                            value={pageData.seoText.p2}
                            onChange={(e)=>{
                                setPageData({...pageData, seoText: {...pageData.seoText, p2: e.target.value} });
                            }}
                        />

                        <h4 className="mb-1 pl-1">Список:</h4>
                        <Textarea
                            value={pageData.seoText.list}
                            onChange={(e)=>{
                                setPageData({...pageData, seoText: {...pageData.seoText, list: e.target.value} });
                            }}
                        />

                        <h4 className="mb-1 pl-1">Последний абзац:</h4>
                        <Textarea
                            value={pageData.seoText.p3}
                            onChange={(e)=>{
                                setPageData({...pageData, seoText: {...pageData.seoText, p3: e.target.value} });
                            }}
                        />
                    </div>
                </div>

                <div id="femaleTabBlock" className={`row ${cl.tabBlock} ${activeTab === 'female' && cl.active}`}>
                    <div className="col-12 my-3">
                        <h4 className="mb-1 pl-1">Заголовок, девушки:</h4>
                        <Input type={"text"}
                               value={pageData.seoTextFemale.header}
                               setValue={ (value) => setPageData( {...pageData, seoTextFemale: {...pageData.seoTextFemale, header: value} }) }
                        />

                        <h4 className="mt-3 mb-1 pl-1">Первый абзац, девушки:</h4>
                        <Textarea
                            value={pageData.seoTextFemale.p1}
                            onChange={(e)=>{
                                setPageData({...pageData, seoTextFemale: {...pageData.seoTextFemale, p1: e.target.value} });
                            }}
                        />

                        <h4 className="mb-1 pl-1">Второй абзац, девушки:</h4>
                        <Textarea
                            value={pageData.seoTextFemale.p2}
                            onChange={(e)=>{
                                setPageData({...pageData, seoTextFemale: {...pageData.seoTextFemale, p2: e.target.value} });
                            }}
                        />

                        <h4 className="mb-1 pl-1">Список, девушки:</h4>
                        <Textarea
                            value={pageData.seoTextFemale.list}
                            onChange={(e)=>{
                                setPageData({...pageData, seoTextFemale: {...pageData.seoTextFemale, list: e.target.value} });
                            }}
                        />

                        <h4 className="mb-1 pl-1">Последний абзац, девушки:</h4>
                        <Textarea
                            value={pageData.seoTextFemale.p3}
                            onChange={(e)=>{
                                setPageData({...pageData, seoTextFemale: {...pageData.seoTextFemale, p3: e.target.value} });
                            }}
                        />
                    </div>
                </div>

                <div id="maleTabBlock" className={`row ${cl.tabBlock} ${activeTab === 'male' && cl.active}`}>
                    <div className="col-12 my-3">
                        <h4 className="mb-1 pl-1">Заголовок, парни:</h4>
                        <Input type={"text"}
                               value={pageData.seoTextMale.header}
                               setValue={ (value) => setPageData( {...pageData, seoTextMale: {...pageData.seoTextMale, header: value} }) }
                        />

                        <h4 className="mt-3 mb-1 pl-1">Первый абзац, парни:</h4>
                        <Textarea
                            value={pageData.seoTextMale.p1}
                            onChange={(e)=>{
                                setPageData({...pageData, seoTextMale: {...pageData.seoTextMale, p1: e.target.value} });
                            }}
                        />

                        <h4 className="mb-1 pl-1">Второй абзац, парни:</h4>
                        <Textarea
                            value={pageData.seoTextMale.p2}
                            onChange={(e)=>{
                                setPageData({...pageData, seoTextMale: {...pageData.seoTextMale, p2: e.target.value} });
                            }}
                        />

                        <h4 className="mb-1 pl-1">Список, парни:</h4>
                        <Textarea
                            value={pageData.seoTextMale.list}
                            onChange={(e)=>{
                                setPageData({...pageData, seoTextMale: {...pageData.seoTextMale, list: e.target.value} });
                            }}
                        />

                        <h4 className="mb-1 pl-1">Последний абзац, парни:</h4>
                        <Textarea
                            value={pageData.seoTextMale.p3}
                            onChange={(e)=>{
                                setPageData({...pageData, seoTextMale: {...pageData.seoTextMale, p3: e.target.value} });
                            }}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-4">
                        <Button type={'primary'} onClick={edit ? updatePage : createPage}>Сохранить</Button>
                    </div>
                </div>
            </form>

            <div id="showMainTextRow" className={`row ${cl.tabBlock} ${activeTab === 'main' && cl.active}`}>
                <div className="col-12">
                    <h1 className="text-center m-3 m-lg-5">Так будет выглядеть текст на основной странице</h1>
                    <div className="m-3 m-lg-5">
                        <h2 className="mb-lg-3">{pageData.seoText.header ?? ''}</h2>

                        <p className="mb-3">{pageData.seoText.p1 ?? ''}</p>
                        <p className="mb-3">{pageData.seoText.p2 ?? ''}</p>

                        {pageData.ul && pageData.ul.length > 0 &&
                            <ul className={cl.seoUlList}>
                                { pageData.ul.map( (text, index) => {
                                    if ( text.trim().length > 0){
                                        return <li key={index}>{text}</li>;
                                    }
                                    return '';
                                })}
                            </ul>
                        }

                        <p className="mb-3">{pageData.seoText.p3 ?? ''}</p>
                    </div>
                </div>
            </div>

            <div id="showFemaleTextRow" className={`row ${cl.tabBlock} ${activeTab === 'female' && cl.active}`}>
                <div className="col-12">
                    <h1 className="text-center m-3 m-lg-5">Так будет выглядеть текст на странице девушки</h1>
                    <div className="m-3 m-lg-5">
                        <h2 className="mb-lg-3">{pageData.seoTextFemale.header ?? ''}</h2>

                        <p className="mb-3">{pageData.seoTextFemale.p1 ?? ''}</p>
                        <p className="mb-3">{pageData.seoTextFemale.p2 ?? ''}</p>

                        {pageData.ulFemale && pageData.ulFemale.length > 0 &&
                            <ul className={cl.seoUlList}>
                                { pageData.ulFemale.map( (text, index) => {
                                    if ( text.trim().length > 0){
                                        return <li key={index}>{text}</li>;
                                    }
                                    return '';
                                })}
                            </ul>
                        }

                        <p className="mb-3">{pageData.seoTextFemale.p3 ?? ''}</p>
                    </div>
                </div>
            </div>

            <div id="showMaleTextRow" className={`row ${cl.tabBlock} ${activeTab === 'male' && cl.active}`}>
                <div className="col-12">
                    <h1 className="text-center m-3 m-lg-5">Так будет выглядеть текст на странице парни</h1>
                    <div className="m-3 m-lg-5">
                        <h2 className="mb-lg-3">{pageData.seoTextMale.header ?? ''}</h2>

                        <p className="mb-3">{pageData.seoTextMale.p1 ?? ''}</p>
                        <p className="mb-3">{pageData.seoTextMale.p2 ?? ''}</p>

                        {pageData.ulMale && pageData.ulMale.length > 0 &&
                            <ul className={cl.seoUlList}>
                                { pageData.ulMale.map( (text, index) => {
                                    if ( text.trim().length > 0){
                                        return <li key={index}>{text}</li>;
                                    }
                                    return '';
                                })}
                            </ul>
                        }

                        <p className="mb-3">{pageData.seoTextMale.p3 ?? ''}</p>
                    </div>
                </div>
            </div>

        </>
    );
};

export default CreateSeoPage;
