require('dotenv').config();

export const API_URL = process.env.REACT_APP_API_URL;
export const FRONT_URL = process.env.REACT_APP_FRONT_URL;
export const GOOGLE_API_KEY = `AIzaSyBlQgtQTBC9Ny54-sXN3-s4VBj1W_N9MGc`;

/*Profile Relationship*/
export const ProfileRelationship = [
    {value: 1, name: 'Свободен -(на)'},
    {value: 2, name: 'Занят -(та)'},
    {value: 3, name: 'Все сложно'},
];
/*Profile Orientation*/
export const ProfileOrientation = [
    {value: 1, name: 'Гетеро'},
    {value: 2, name: 'Гомо'},
    {value: 3, name: 'Гей'}
];
/*Profile Living*/
export const ProfileLiving = [
    {value: 1, name: 'Один/Одна'},
    {value: 2, name: 'С родителями'},
    {value: 3, name: 'Со второй половиной'},
    {value: 4, name: 'С соседями'},
    {value: 5, name: 'В общежитии'},
];
/*Profile Children*/
export const ProfileChildren = [
    {value: 1, name: 'Еще нет'},
    {value: 2, name: 'Есть'},
    {value: 3, name: 'Уже взрослые'},
    {value: 4, name: 'Нет, и не будет'}
];
/*Profile Smoking*/
export const ProfileSmoking = [
    {value: 1, name: 'Курю'},
    {value: 2, name: 'Не курю'},
    {value: 3, name: 'Не приемлю'}
];
/*Profile Alcohol*/
export const ProfileAlcohol = [
    {value: 1, name: 'Не пью'},
    {value: 2, name: 'Выпиваю в компании'},
    {value: 3, name: 'Пью много'},
    {value: 4, name: 'Не приемлю'}
];

export const ProfileInterests = [
    {value: 1, name: 'Политика'},
    {value: 2, name: 'Компьютерные игры'},
    {value: 3, name: 'Настольные игры'},
    {value: 4, name: 'Ролевые игры'},
    {value: 5, name: 'Путешествия'},
    {value: 6, name: 'Клубы и тусовки'},
    {value: 7, name: 'Чтение'},
    {value: 8, name: 'Кино'},
    {value: 9, name: 'Музыка'},
    {value: 10, name: 'Искусство'},
    {value: 11, name: 'Эротика'},
    {value: 12, name: 'Наука'},
    {value: 13, name: 'Фотография'},
    {value: 14, name: 'Духовность'},
    {value: 15, name: 'Танцы'},
    {value: 16, name: 'Спорт'},
    {value: 17, name: 'Футбол'},
    {value: 18, name: 'Рыбалка'},
    {value: 19, name: 'Кулинария'},
    {value: 20, name: 'Камасутра'},
    {value: 21, name: 'Домашние животные'},
    {value: 22, name: 'Чай'},
    {value: 23, name: 'Кофе'},
    {value: 24, name: 'Выпить с друзьями'},
    {value: 25, name: 'Мода'},
    {value: 26, name: 'Шопинг'},
    {value: 27, name: 'Иностранные языки'}
];

export const Goals = [
    {value: 1, name: 'Дружбы'},
    {value: 2, name: 'Романтики'},
    {value: 3, name: 'Отношений'},
    {value: 4, name: 'Секса'},
    {value: 5, name: 'Путешествия'}
];

export const fondyPaymentOptions = {
    options: {
        fullScreen: false,
        fee: false,
        fields: false,
        methods: ["card"],
        methods_disabled: [],
        title: 'roomba.me',
        cardIcons: ["mastercard", "visa"],
        email: false,
        locales: ['ru'],
        active_tab: 'card',
        theme: {
            type: "light",
            preset: "black"
        },
    },
    params: {},
    messages: {
        ru: {
            pay: "Оплатить"
        }
    }
};
