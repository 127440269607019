import React, {useContext} from 'react';
import styles from './Notifications.module.css';
import Notification from "./notification/Notification";
import {AppContext} from "../../context";

const Notifications = () => {
    const {notifications} = useContext(AppContext);

    const drawNotifications = notifications.map((notification)=>{
        return(
            <Notification
                link={notification.link}
                key={notification.id}
                id={notification.id}
                type={notification.type}
                className={notification.className}>
                    {notification.children}
            </Notification>
        );
    });

    return (
        <div id='notifications' className={styles.notifications}>
            {drawNotifications}
        </div>
    );
};

export default Notifications;
