import React from 'react';
import Input from "../../../ui/input/Input";
import {Link} from "react-router-dom";
import cl from "./Page.module.css";
import {FRONT_URL} from "../../../../core/config";

const Page = ({page}) => {

    return (
        <div className="row no-gutters">
            <div className="col-lg-4 border-bottom py-2 text-center">
                <div className="input-block">
                    <Input type={"text"}
                           value={page.cityName}
                           // setValue={ (value) => page( {...page, cityName:value}) }
                           // error={fieldsErrors.cpa}
                           disabled={true}
                    />
                </div>
            </div>
            <div className="col-lg-4 border-bottom py-2 text-center">
                <div className="input-block">
                    <Input type={"text"}
                           value={page.slug}
                           // setValue={ (value) => page( {...page, slug:value}) }
                           // error={fieldsErrors.type}
                           disabled={true}
                    />
                </div>
            </div>
            <div className="col-lg-4 font-weight-bold border-bottom text-center">
                <div className="input-block pt-1">
                    <Link className={cl.pageLink} to={`/new-seo-page?pageSlug=${page.slug}`}>Редактировать</Link>
                    <a className={cl.pageLink} href={`${FRONT_URL}/znakomstva/${page.slug}`} target="_blank" rel="noreferrer">На страницу</a>
                </div>
            </div>
        </div>
    );
};

export default Page;
