import React, {useContext} from 'react';
import "./AdminMain.css";
import Header from "../header/Header";
import NavigationBar from "../../navigationBar/NavigationBar";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
// import Moderation from "../moderation/Moderation";
// import Users from "../users/Users";
import Offers from "../offers/Offers";
import CreateSeoPage from "../seoPages/CreateSeoPage";
import {AppContext} from "../../../context";
import SeoPagesList from "../seoPages/SeoPagesList";
import ImportXls from "../seoPages/ImportXls";
import Statistics from "../statistic/Statistics";

const AdminMain = () => {
    const {isAuth} = useContext(AppContext);
    return (
        <BrowserRouter>
            <div className="App">
                <Header/>
                <div className="AppContent">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-2 admin-sidebar">
                                <NavigationBar/>
                            </div>
                            <div className="col-lg-10 admin-main-content">
                                <Switch>
                                    {/*<Route exact path="/moderation" component={Moderation}/>*/}
                                    {/*<Route exact path="/users" component={Users}/>*/}
                                    {isAuth.capability < 2 &&
                                    <>
                                        <Route exact path="/offers" component={Offers}/>
                                        <Route exact path="/new-seo-page" component={CreateSeoPage}/>
                                        <Route exact path="/seo-pages" component={SeoPagesList}/>
                                        <Route exact path="/seo-import-xls" component={ImportXls}/>
                                        <Route exact path="/stats" component={Statistics}/>
                                    </>
                                    }
                                    <Redirect exact from="/" to="/moderation" />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BrowserRouter>
    );
};

export default AdminMain;
