import React, {useContext, useState} from 'react';
import cl from './NavigationBar.module.css';
import {NavLink} from "react-router-dom";
import {AppContext} from "../../context";
import CollapsedMenu from "./collapsedMenu/CollapsedMenu";

const NavigationBar = () => {
    const {isAuth} = useContext(AppContext);
    const [collapseMenu, setCollapseNenu] = useState(true);

    const switchCollapse = () => {
        setCollapseNenu(!collapseMenu);
    }
    return (
        <nav className={cl.sidebarMenu}>
            <ul className="d-flex flex-wrap d-lg-block">
                {/*<li className="pr-5 pr-lg-0">*/}
                {/*    <NavLink*/}
                {/*        style={isActive => ({*/}
                {/*            backgroundColor: isActive ? "rgb(44 51 56)" : "transparent"*/}
                {/*        })}*/}
                {/*        to="/moderation">Модерация</NavLink>*/}
                {/*</li>*/}
                {/*<li className="pr-5 pr-lg-0">*/}
                {/*    <NavLink*/}
                {/*        style={isActive => ({*/}
                {/*            backgroundColor: isActive ? "rgb(44 51 56)" : "transparent"*/}
                {/*        })}*/}
                {/*        to="/users">Пользователи</NavLink>*/}
                {/*</li>*/}
                {isAuth.capability < 2 &&
                    <>
                        <li className="pr-5 pr-lg-0">
                            <NavLink
                                style={isActive => ({
                                    backgroundColor: isActive ? "rgb(44 51 56)" : "transparent"
                                })}
                                to="/offers">Офферы</NavLink>
                        </li>
                        <li className="pr-5 pr-lg-0">
                            <NavLink
                                style={isActive => ({
                                    backgroundColor: isActive ? "rgb(44 51 56)" : "transparent"
                                })}
                                to="/stats">Статистика</NavLink>
                        </li>
                        <li className="pr-5 pr-lg-0" onClick={switchCollapse}>
                            <span>Seo страницы</span>
                            <CollapsedMenu
                                links={[
                                    {slug: '/new-seo-page', name: 'Новая страница'},
                                    {slug: '/seo-pages', name: 'Список страниц'},
                                    {slug: '/seo-import-xls', name: 'Импорт XLS'}
                                ]}
                                collapsed={collapseMenu}
                            />
                        </li>
                    </>
                }
            </ul>
        </nav>
    );
};

export default NavigationBar;
