import React from 'react';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import Offer from "./offer";

const OffersList = ({offers, removeOffer}) => {
    return (
        <TransitionGroup>
            { offers.map((offer) =>
                <CSSTransition
                    key={offer._id}
                    timeout={500}
                    classNames="item"
                >
                    <Offer offer={offer} removeOffer={removeOffer} />
                </CSSTransition>
            )}
        </TransitionGroup>
    );
};

export default OffersList;
