import React from 'react';
import Spinner from "../spinner/Spinner";

const ContentLoader = ({opacity=false, size=false}) => {
    return (
        <div style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: '100%',
            background: `rgba(255,255,255, ${opacity ? opacity : 1})`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 100
        }}>
            <Spinner size={size}/>
        </div>
    );
};

export default ContentLoader;
