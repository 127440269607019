import $api from "../core/api";
import {errorProcessing} from "../core/helpers";
import {createNotification} from "./appActions";

/**
 * SEO actions
 */

export const addSeoPage = (pageData, setPageData, notifications, setNotifications, setFieldsErrors, setEdit) => {
    setFieldsErrors({cityName:"", slug:""});
    $api.post("/api/seo/add-page", pageData, {withCredentials: true})
        .then((response) => {
            if ( response.data.success === true ){
                const pageData = prepareListsToRender(response.data.page);
                setPageData(pageData);
                setEdit(true);
                createNotification({
                    type: 'primary',
                    children: 'Страница успешно создана'
                }, notifications, setNotifications);
            }
        })
        .catch((e) => errorProcessing(e, setFieldsErrors, notifications, setNotifications));
}

export const updateSeoPage = (pageData, setPageData, notifications, setNotifications, setFieldsErrors) => {
    setFieldsErrors({cityName:"", slug:""});
    $api.post("/api/seo/update-page", pageData,  {withCredentials: true})
        .then((response) => {
            if ( response.data.success === true ){
                const pageData = prepareListsToRender(response.data.page);
                setPageData(pageData);
                createNotification({
                    type: 'primary',
                    children: 'Страница успешно обновлена'
                }, notifications, setNotifications);
            }
        })
        .catch((e) => errorProcessing(e, setFieldsErrors, notifications, setNotifications));
}

export const getSeoPageData = (pageSlug, setPageData, notifications, setNotifications) => {
    $api.get(`/api/seo/get-page-data/${pageSlug}`,   {withCredentials: true})
        .then((response) => {
            if ( response.data.success === true ){
                const pageData = prepareListsToRender(response.data.page);
                setPageData(pageData);
            }
        })
        .catch((e) => {
            createNotification({
                type: 'error',
                children: e.message
            }, notifications, setNotifications);
        });
}

export const importXlsData = (file, setImportResult, notifications, setNotifications) => {
    const formData = new FormData();
    formData.append( 'xls', file, file.name );

    $api.post("/api/seo/import-xls", formData,  {withCredentials: true})
        .then((response) => {
            if ( response.data.success === true ){
                setImportResult(response.data.result);
            } else {
                createNotification({
                    type: 'error',
                    children: 'Ошибка импорта'
                }, notifications, setNotifications);
            }
        })
        .catch((e) => errorProcessing(e, notifications, setNotifications));
}

const prepareListsToRender = (pageData) => {
    if (pageData.seoText.list){
        pageData.ul = pageData.seoText.list.split(';;');
    }
    if (pageData.seoTextMale.list){
        pageData.ulMale = pageData.seoTextMale.list.split(';;');
    }
    if (pageData.seoTextFemale.list){
        pageData.ulFemale = pageData.seoTextFemale.list.split(';;');
    }
    return pageData;
}
