import React from 'react';

const Header = () => {
    return (
        <header className="App-header">
            <p className="App-logo container-fluid">Roomba Admin Panel</p>
        </header>
    );
};

export default Header;
