import React from 'react';
import AuthBox from "./authBox/AuthBox";
import Header from "../header/Header";

const LoginPage = () => {
    return (
        <div className="App">
            <Header/>
            <div className="container">
                <div className="row">
                    <div className="col d-flex align-items-center justify-content-center py-5">
                        <AuthBox/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
