import React from 'react';
import classes from './InputError.module.css';

const InputError = ({error}) => {
    return (
        <div className={classes.input__error}>
            {error}
        </div>
    );
};

export default InputError;