import React, {useContext, useEffect, useState} from 'react';
import styles from './Notification.module.css';
import { CSSTransition } from 'react-transition-group';
import {useSwipeable} from "react-swipeable";
import {deleteNotificationAction} from "../../../actions/appActions";
import {AppContext} from "../../../context";

const Notification = ({id, type, children, className}) => {
    const {notifications, setNotifications} = useContext(AppContext);
    const [show, setShow] = useState(true);
    const [css, setCss] = useState(false);

    const notificationStyle = type === 'primary' ? styles.Primary : styles.Error;
    const notificationClasses = [styles.Notification, notificationStyle];
    if(className && styles[className]){
        notificationClasses.push(styles[className]);
    }

    useEffect(()=>{
        let timeOutClose = setTimeout(() => {
            closeNotification();
        }, 6000);

        return ()=>{
            clearTimeout(timeOutClose);
        };
        // eslint-disable-next-line
    },[]);

    useEffect(()=>{
        if(!show){
            let timeOutClose = setTimeout(() => {
                deleteNotificationAction(id, notifications, setNotifications);
            }, 600);

            return ()=>{
                clearTimeout(timeOutClose);
            };
        }
        // eslint-disable-next-line
    },[show, id]);


    const closeNotification = () => {
        const notification = document.getElementById(id);

        setShow(false);

        notification.style = `
            transform:translateY(-100px);
            opacity: 0;
            height: 0;
            margin-bottom: 0;
            z-index: 0;
        `;
    };

    const handlers = useSwipeable({
        onSwiped: (data) => {
            if(data.deltaY < -50){
                closeNotification();
                return false;
            }else{
                setCss({
                    transform: `translateY(0)`,
                    opacity: `1`,
                    zIndex: `1500`,
                });
            }
        },
        onSwiping: (data) => {
            //close
            if(data.deltaY < -50){
                closeNotification();
                return false;
            }

            if(data.deltaY < 0){
                setCss({
                    transform: `translateY(${data.deltaY}px)`,
                    opacity: `${1 - (-data.deltaY / 100)}`,
                    zIndex: `0`,
                });
            }else{
                setCss({
                    transform: `translateY(0)`,
                    opacity: `1`,
                    zIndex: `1500`,
                });
            }
        }
    });

    return (
        <CSSTransition
            in={show}
            timeout={0}
            classNames={{
                enterActive: styles.NotificationActive,
                enterDone: styles.NotificationDone,
                exit: styles.NotificationExit,
                exitDone: styles.NotificationExitDone
            }}
            appear={true}
        >
            <div id={id}
                 className={`${notificationClasses.join(' ')}`}
                 {...css && {style: css}}
                 {...handlers}>
                <div className={styles.NotificationContent}
                     onClick={()=>{closeNotification()}}>
                    {children}
                </div>
                <span onClick={()=>{closeNotification()}} className={styles.NotificationClose}>
                    &times;
                </span>
            </div>
        </CSSTransition>
    );
};

export default Notification;
