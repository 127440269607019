import React, {useContext, useEffect} from 'react';
import cl from './AppLoader.module.css';
import {AppContext} from "../../../context";

const AppLoader = () => {
    const {isLoading} = useContext(AppContext);

    useEffect(()=>{
        document.body.classList.toggle('hidden', isLoading);
    },[isLoading]);

    return (
        <div className={cl.loaderWrapper}>
            <div className={cl.appSpinner}>
                <div className={cl.appSpinnerElement}></div>
                <div className={cl.appSpinnerElement}></div>
            </div>
            <div className={cl.logo}>
                Roomba
            </div>
        </div>
    );
};

export default AppLoader;
