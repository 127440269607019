import React, {useEffect, useState} from 'react';
import InputError from "../inputError/InputError";
import classes from './Input.module.css';

const Input = (props) => {
    const {placeholder, setValue, value, error, label, ...rest} = props;
    const [focused, setFocused] = useState(!!value);
    const onFocus = () => setFocused(true);
    const onBlur = (e) => {if(!e.target.value){ setFocused(false);}};
    const onChange = (e) => {
        if(e.target.value){
            setFocused(true)
        }else{
            setFocused(false)
        }
        setValue(e.target.value)
    };

    useEffect(()=>{
        if(value){
            setFocused(true);
        }else{
            setFocused(false);
        }
    },[value]);

    return (
        <div className={classes.app__field__row}>
            {label && <div className={classes.app__input__label}>{label}</div>}
            <div className={focused ? `${classes.app__input} ${classes.focused}` : `${classes.app__input}`}>
                <div className={classes.container}>
                    <input {...rest}
                           onFocus={onFocus}
                           onBlur={(e)=>{onBlur(e)}}
                           value={value}
                           onChange={(e)=>{onChange(e)}}
                           {...(error && {className:classes.error})}
                    />
                </div>
                <div aria-hidden="true" className={classes.app__input__placeholder}>{placeholder}</div>
                {error && <InputError error={error}/>}
            </div>
        </div>
    );
};

export default Input;