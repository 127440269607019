import $api from "../core/api";
import {setStatWebs} from "../reducers/statReducer";

export const getStatistics = async (data) => {
    const response = await $api.post("/api/admin/fstat", data, {withCredentials: true});
    return response.data;
};

export const getWebs = () => {
    return async dispatch => {
        try {
            const response = await $api.get("/api/admin/webs", {withCredentials: true});
            dispatch(setStatWebs(response.data.webs));
        } catch (e) {
            alert("Ошибка получения данных")
        }
    };
};
