import React, {useContext, useEffect, useState} from 'react';
import $api from "../../../core/api";
import "./Offers.css";
import OffersList from "./OffersList";
import Input from "../../ui/input/Input";
import Select from "../../ui/select/Select";
import Button from "../../ui/button/Button";
import {AppContext} from "../../../context";
import {errorProcessing} from "../../../core/helpers";

const Offers = () => {
    const {notifications, setNotifications} = useContext(AppContext);
    const [offers, setOffers] = useState([]);
    const [offersCount, setOffersCount] = useState({countAll: 0, count: 0});
    // const [page, setPage] = useState(1);
    const [newOffer, setNewOffer] = useState({cpa:"", type:"", goal:"", payout:0, currency:0});
    const [fieldsErrors, setFieldsErrors] = useState({cpa:"", type:"", goal:"", payout:"", currency:""});

    const getOffers = (page) => {
        $api.get(`/api/admin/offers?page=${page}`)
            .then((response) => {
                if ( response.data.success === true ){
                    setOffers(response.data.offers);
                    setOffersCount({countAll: response.data.countAll, count: response.data.count });
                }
            })
            .catch((e) => {
                console.log(e.message);
            });
    }

    const removeOffer = (uid) => {
        setOffers([...offers.filter( item => item._id !== uid )]);
        setOffersCount({countAll: offersCount.countAll - 1, count: offersCount.count - 1});
    }

    const addOffer = () => {
        setFieldsErrors({cpa:"", type:"", goal:"", payout:"", currency:""});
        $api.post("/api/admin/add-offer", newOffer,  {withCredentials: true})
            .then((response) => {
                if ( response.data.success === true ){
                    setNewOffer({cpa:"", type:"", goal:"", payout:0, currency:0})
                    setOffers([response.data.offer, ...offers]);
                    setOffersCount({countAll: offersCount.countAll + 1, count: offersCount.count + 1});
                }
            })
            .catch((e) => errorProcessing(e, setFieldsErrors, notifications, setNotifications));
    }

    useEffect(() => {
        getOffers(1);
    }, []);

    return (
        <>
            <h2 className="mt-3 text-center">Офферы</h2>

            <div>Показано {offersCount.count} из {offersCount.countAll} офферов <span className="offers-list-reload" title="Обновить список офферов" onClick={() => getOffers(1)}>Обновить список <span>&#8635;</span></span></div>

            <div className="mainContentWrapper">
                <div className="row no-gutters mb-2" style={{backgroundColor:'#eeeeee'}}>
                    <div className="col-lg-2 font-weight-bold border-dark-1 py-2 text-center">CPA</div>
                    <div className="col-lg-2 font-weight-bold border-dark-1 py-2 text-center">Оффер</div>
                    <div className="col-lg-3 font-weight-bold border-dark-1 py-2 text-center">Цель</div>
                    <div className="col-lg-2 font-weight-bold border-dark-1 py-2 text-center">Сумма</div>
                    <div className="col-lg-1 font-weight-bold border-dark-1 py-2 text-center">Валюта</div>
                    <div className="col-lg-2 font-weight-bold border-dark-1 py-2 text-center">Действия</div>
                </div>
                <div className="row no-gutters">
                    <div className="col-lg-2 border-dark-1 py-2 text-center">
                        <div className="input-block">
                            <Input type={"text"}
                                   value={newOffer.cpa}
                                   setValue={ (value) => setNewOffer( {...newOffer, cpa:value}) }
                                   error={fieldsErrors.cpa}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 border-dark-1 py-2 text-center">
                        <div className="input-block">
                            <Input type={"text"}
                                   value={newOffer.type}
                                   setValue={ (value) => setNewOffer( {...newOffer, type:value}) }
                                   error={fieldsErrors.type}
                            />
                        </div>
                    </div>
                    <div className="col-lg-3  border-dark-1 py-2 text-center">
                        <div className="input-block">
                            <Input type={"text"}
                                   value={newOffer.goal}
                                   setValue={ (value) => setNewOffer( {...newOffer, goal:value}) }
                                   error={fieldsErrors.goal}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 border-dark-1 py-2 text-center">
                        <div className="input-block">
                            <Input type={"number"}
                                   value={newOffer.payout}
                                   setValue={ (value) => setNewOffer( {...newOffer, payout:value}) }
                                   error={fieldsErrors.payout}
                            />
                        </div>
                    </div>
                    <div className="col-lg-1 border-dark-1 py-2 text-center">
                        <div className="input-block">
                            <Select
                                value={newOffer.currency}
                                onChange={(value)=>{
                                    setNewOffer({...newOffer, currency:value});
                                }}
                                defaultValue="Валюта..."
                                options={[
                                    {value: 1, name: 'EUR'},
                                    {value: 2, name: 'USD'},
                                    {value: 3, name: 'UAH'},
                                    {value: 4, name: 'RUB'},
                                    {value: 5, name: 'TNG'},
                                ]}
                                error={fieldsErrors.currency}
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 font-weight-bold border-dark-1 py-2 text-center">
                        <div className="input-block">
                            <Button type={'link'} onClick={addOffer}>Добавить</Button>
                        </div>
                    </div>
                </div>
                { offers.length > 0
                    ?
                    <OffersList offers={offers} removeOffer={removeOffer} />
                    :
                    <div className="text-center py-3">Офферы пока не заведены</div>
                }
            </div>
        </>
    );
};

export default Offers;
