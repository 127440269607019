const SET_STAT_WEBS = 'SET_STAT_WEBS';

const defaultState = {
    webs: [],
    loaded: false
};

export default function statReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_STAT_WEBS:
            return {...state, webs: action.payload, loaded: true};
        default:
            return state
    }
}

export const setStatWebs = (set) => ({type: SET_STAT_WEBS, payload: set});
