import {API_URL} from '../core/config';
import axios from 'axios';
import $api from '../core/api';
import {createNotification} from "./appActions";

export const login = (email, password, setIsAuth, setIsLoading, notifications, setNotifications) => {
    setIsLoading(true);
    axios.post(`${API_URL}/api/admin/login`, { email, password }, {withCredentials: true})
        .then((response) => {
            setIsLoading(false);
            if (response.data.user.capability < 2) {
                localStorage.setItem('admToken', response.data.accessToken);
                localStorage.setItem('admRefreshToken', response.data.refreshToken);
                setIsAuth({isAuth: true, capability: response.data.user.capability});
            } else {
                localStorage.removeItem('admToken');
                localStorage.removeItem('admRefreshToken');
                createNotification({
                    type: 'error',
                    children: 'Недостаточный уровень доступа',
                    className: 'Important'
                }, notifications, setNotifications);
            }
        })
        .catch((e) => {
            setIsLoading(false);
            if (e.response) {
                const response = e.response;
                const errors = response.data.errors;if (errors && Array.isArray(errors) && errors.length > 0) {
                    // setUserErrors(errors);
                } else {
                    const msgText = response.data.message ? response.data.message : 'Что-то пошло не так!';
                    //popup Unknown error
                    createNotification({
                        type: 'error',
                        children: msgText
                    }, notifications, setNotifications);
                }
            } else {
                createNotification({
                    type: 'error',
                    children:  e.request ? 'Ошибка подключения' : 'Непредвиденная ошибка'
                }, notifications, setNotifications);
            }
        });
}

export const checkAuth = (setIsAuth, setIsLoading, notifications, setNotifications) => {
    setIsLoading(true);
    const rToken = localStorage.getItem('admRefreshToken');
    $api.get(`/api/admin/refresh?refreshToken=${rToken}`)
        .then((response) => {
            setIsLoading(false);
            if (response.data.user.capability < 2) {
                localStorage.setItem('admToken', response.data.accessToken);
                localStorage.setItem('admRefreshToken', response.data.refreshToken);
                setIsAuth({isAuth: true, capability: response.data.user.capability});
            } else {
                localStorage.removeItem('admToken');
                localStorage.removeItem('admRefreshToken');
                createNotification({
                    type: 'error',
                    children: 'Недостаточный уровень доступа',
                    className: 'Important'
                }, notifications, setNotifications);
            }
        }).catch((e) => {
            setIsLoading(false);
            localStorage.removeItem('admToken');
            localStorage.removeItem('admRefreshToken');
            createNotification({
                type: 'error',
                children: 'Ошибка авторизации.',
                className: 'Important'
            }, notifications, setNotifications);
        });
};
