import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/reset.css';
import './assets/css/bootstrap-grid.css';
import App from "./components/app/App";
import {store} from './reducers';
import {Provider} from 'react-redux';

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('app')
);

